import { useContext, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/table';
import { Button } from '@nextui-org/button';
import { Input } from '@nextui-org/input';
import React from 'react';
import { MdCancel, MdDeleteForever } from 'react-icons/md';
import { RxOpenInNewWindow } from 'react-icons/rx';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Snippet,
  Tooltip,
} from '@nextui-org/react';
import { GiConfirmed } from 'react-icons/gi';
import { FaEdit } from 'react-icons/fa';
import { t } from 'i18next';

import StoreContext from '@/scripts/storeContext';
import { open_anydesk } from '@/client_scripts/sockets_script';
import { delete_entry, get_enteries } from '@/client_scripts/fetch_tikcets_api';

import NewEnteryForm from '../NewEnteryForm/NewEnteryForm';
import EditEntry from '../EditEntry/EditEntry';

import styles from './EnteriesTable.module.css';

export default function EnteriesTable(props: {
  customer: string;
  RoleId: number;
}) {
  const {
    enteries,
    setEnteries,
    setGettingEntries,
    gettingEntries,
    setMError,
  } = useContext(StoreContext);
  const server_word = ['serv', 'ph', 'bmlab', 'vm', 'pass'];
  const [reload, setReload] = useState(false);
  const [openPopOver, setOpenPopOver] = useState(-1);
  const [onDelete, setOnDelete] = useState(-1);
  const [onEdit, setOnEdit] = useState(-1);
  const [onLoad, setOnLoad] = useState(-1);

  useEffect(() => {
    setGettingEntries(true);
    const get_tik = async () => {
      const mticketss = await get_enteries(props.customer);
      const mtickets = await mticketss.json();

      setEnteries(mtickets.enteries);
      console.log(mtickets.enteries);
      setGettingEntries(false);
    };

    get_tik();
  }, [props.customer, reload]);

  return (
    <>
      {!gettingEntries ? (
        <Table
          isHeaderSticky
          aria-label="Example table with custom cells"
          className="animate-tada"
          classNames={{
            base: `z-40 overflow-auto hide-scroller-child ${styles.table_body}`,
          }}
        >
          <TableHeader>
            <TableColumn className="text-center">Delete</TableColumn>
            <TableColumn className="text-center">ID</TableColumn>
            <TableColumn className="text-center">Name</TableColumn>
            <TableColumn className="text-center">Value</TableColumn>
            <TableColumn className="text-center">Open</TableColumn>
          </TableHeader>
          <TableBody>
            <TableRow key="new">
              <TableCell>
                <div />
              </TableCell>
              <TableCell>
                <NewEnteryForm customer={props.customer} reload={setReload} />
              </TableCell>
              <TableCell>
                <Input className="w-full" />
              </TableCell>
              <TableCell>
                <Input className="w-full" />
              </TableCell>
              <TableCell>
                <Input className="w-full" />
              </TableCell>
            </TableRow>
            {enteries
              ?.sort((Mitem: any, _b: any) =>
                server_word.filter((mitem) =>
                  Mitem.EntryName.toLowerCase().includes(mitem),
                ).length
                  ? -1
                  : 1,
              )
              .map((item: any) => (
                <TableRow key={item.EntryId}>
                  <TableCell className="flex text-center">
                    {props.RoleId === 1 ? (
                      <Popover
                        showArrow
                        backdrop="transparent"
                        isOpen={openPopOver == item.EntryId}
                        offset={10}
                        placement="top"
                      >
                        <PopoverTrigger>
                          <Button
                            isIconOnly
                            className="capitalize"
                            isDisabled={onDelete != -1}
                            isLoading={onDelete == item.EntryId}
                            radius="full"
                            size="sm"
                            variant="light"
                            onClick={() =>
                              setOpenPopOver((prev) =>
                                prev != -1 ? -1 : item.EntryId,
                              )
                            }
                          >
                            <MdDeleteForever />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="flex flex-row">
                          <Tooltip content={`Delete`}>
                            <Button
                              isIconOnly
                              color="danger"
                              radius="full"
                              size="sm"
                              variant="light"
                              onPress={async () => {
                                setOnDelete(item.EntryId);
                                const delete_ent = await delete_entry(
                                  item.EntryId,
                                );

                                if (!delete_ent.ok) {
                                  setMError(
                                    'Error accured when deleting Entry!',
                                  );
                                } else {
                                  setReload((prev: boolean) => !prev);
                                }
                                setOnDelete(-1);
                              }}
                            >
                              <GiConfirmed />
                            </Button>
                          </Tooltip>
                          <Tooltip content={`Cancel`}>
                            <Button
                              isIconOnly
                              radius="full"
                              size="sm"
                              variant="light"
                              onClick={() => setOpenPopOver(-1)}
                            >
                              <MdCancel />
                            </Button>
                          </Tooltip>
                        </PopoverContent>
                      </Popover>
                    ) : (
                      ''
                    )}
                    <Tooltip content="Edit">
                      <Button
                        isIconOnly
                        radius="full"
                        size="sm"
                        variant="light"
                        onPress={() => {
                          setOnEdit(item.EntryId);
                        }}
                      >
                        <FaEdit />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell className="text-center">{item.EntryId}</TableCell>
                  <TableCell
                    className={`text-center ${
                      server_word.filter((mitem) =>
                        item.EntryName.toLowerCase().includes(mitem),
                      ).length
                        ? 'font-bold text-red-500'
                        : ''
                    }`}
                  >
                    {item.EntryName}
                  </TableCell>
                  <TableCell className="text-center">{item.ID}</TableCell>
                  <TableCell className="text-center">
                    <Tooltip content={t('Open')}>
                      {[0, 1, 6].includes(item.EntryType) ? (
                        <Button
                          isIconOnly
                          color="success"
                          isLoading={onLoad === item.EntryId}
                          size="md"
                          variant="flat"
                          onClick={async () => {
                            setOnLoad(item.EntryId);
                            if (item.EntryType === 0) {
                              console.log('anydesk');
                              const res: { error: string } | { info: string } =
                                await open_anydesk(item.ID, item.Password);

                              if (
                                (res as any).error &&
                                typeof window !== 'undefined'
                              ) {
                                window.open(`anydesk:${item.ID}`);
                              }
                            } else if (item.EntryType === 1) {
                              window.open(`rustdesk:${item.ID}`);
                            } else if (item.EntryType === 6) {
                              window.open(
                                `teamviewer10://control?device=${item.ID}&authorization=${item.Password}`,
                              );
                            }
                            setOnLoad(-1);
                          }}
                        >
                          <RxOpenInNewWindow size={20} />
                        </Button>
                      ) : (
                        <Snippet
                          hideSymbol
                          classNames={{ base: 'w-min p-0', pre: 'hidden' }}
                          color="success"
                          content={item.ID}
                          size="md"
                          tooltipProps={{ color: 'default' }}
                        />
                      )}
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        ''
      )}
      {onEdit != -1 ? (
        <EditEntry entryId={onEdit} reload={setReload} setEdit={setOnEdit} />
      ) : (
        ''
      )}
    </>
  );
}
