export const englishTranslation = {
  's-User deleted successfully': 'User deleted successfully',
  's-Password reset successfully': 'Password reset successfully',
  Delete: 'Delete',
  Id: 'Id',
  Login: 'Login',
  'Reset password': 'Reset password',
  New: 'New',
  'User Id': 'User Id',
  'User Login': 'User Login',
  delete: 'delete',
  reset: 'reset',
  attachments: 'Attachments',
  add_attachment: 'Add an attachement:',
  type: 'Type',
  name: 'Name',
  date: 'Date',
  user: 'User',
  view_delete: 'View / Delete',
  edit_delete: 'Edit / Delete',
  empty: 'empty',
  close: 'Close',
  ticketHistory: 'Ticket History',
  action: 'Action',
  column: 'Column',
  oldValues: 'Old Values',
  newValues: 'New Values',
  resolveDate: 'Resolve Date',
  stateTime: 'State Time',
  tableLabel: 'Example table with custom cells',
  allItemsSelected: 'All items selected ',
  cancel: 'Cancel',
  edit: 'Edit',
  open: 'Open',
  errorDeleting: 'Error occurred when deleting customer!',
  show: 'Show',
  email: 'Email',
  dns: 'DNS',
  publicId: 'Public Id',
  sms: 'SMS',
  // dashboard
  waiting: 'Waiting',
  resolved: 'Resolved',
  rejected: 'Rejected',
  pending: 'Pending',
  closed: 'Closed',
  Open: 'Open',
  total: 'Total',
  tickets: 'Tickets',
  duration: 'Duration',
};
