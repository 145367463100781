import { Button } from '@nextui-org/button';
import {
  ModalContent,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Select,
  SelectItem,
  Chip,
  Tooltip,
  Snippet,
} from '@nextui-org/react';
import { useContext, useEffect, useState } from 'react';
import { FaRegSave } from 'react-icons/fa';
import { MdDeleteForever, MdOutlineRestartAlt } from 'react-icons/md';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { VscDebugRestart } from 'react-icons/vsc';

import {
  delete_email,
  delete_mail_server,
  generate_smtp,
  get_email,
  get_mail_server,
  insert_mail,
  insert_mail_server,
  update_mail_server,
} from '@/client_scripts/fetch_email_api';
import { show_error } from '@/client_scripts/functions';
import { get_tickets } from '@/client_scripts/fetch_tikcets_api';
import StoreContext from '@/scripts/storeContext';

import Loading from '../Loading/Loading';

export default function EmailForm(props: {
  customerId: number;
  reload: any;
  setEdit: any;
}) {
  const { onOpen, isOpen, onOpenChange, onClose } = useDisclosure();
  const {
    onOpen: onConfirmOpen,
    isOpen: isConfirmOpen,
    onOpenChange: onConfirmOpenChange,
  } = useDisclosure();
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [EmailState, setEmailState] = useState('Checking...');
  const [insertting, setInsertting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [fullName, setFullName] = useState('');
  const [loadData, setLoadData] = useState(false);
  const [smtpPassword, setSmtpValue] = useState('Getting...');
  const [ClientEmails, setClientEmails] = useState<Partial<customer_email>[]>(
    [],
  );
  const [SelectedRecord, setSelectedRecord] = useState<number>(0);
  const [generating_smtp_pass, setGenerating_smtp_pass] = useState(false);
  const [SelectedMailserverMail, setSelectedMailserverMail] =
    useState<any>(null);
  const { setMError } = useContext(StoreContext);

  useEffect(() => {
    if (SelectedRecord === 0) {
      setUserName('');
      setFullName('');
      setSmtpValue('Select record');
      setSelectedMailserverMail(null);
      setEmailState('New');
    } else {
      const record = ClientEmails.find(
        (item) => item.CustomerEmailId == SelectedRecord,
      );

      if (!record) return;
      get_cloudns_record_(record.CustomerEmailName || '');
    }
  }, [SelectedRecord]);

  const get_cloudns_record_ = async (recordName: string) => {
    setLoadData(true);
    setSelectedMailserverMail(null);
    if (!recordName) {
      setLoadData(false);
      setEmailState('Not Found');

      return;
    }
    const email = await get_mail_server({
      userName: recordName,
      domain: 'bmvie.net',
    });
    const mmail = await email.json();

    if (!email.ok) {
      show_error(setMError, mmail);
      setFullName(recordName);
      setLoadData(false);
      setEmailState('Not Found');
      setSelectedMailserverMail(null);

      return;
    }
    console.log(mmail);
    const myrecord = mmail.record;

    if (myrecord.username) {
      setSelectedMailserverMail(myrecord);
      setUserName(myrecord.local_part);
      setFullName(myrecord.name);
      setSmtpValue(myrecord.smtp_password);
      setEmailState('OK');
    } else {
      setSmtpValue('Save to create the email');
      setEmailState('Not Found');
    }
    setLoadData(false);
  };

  useEffect(() => {
    if (props.customerId === -1) return;
    onOpen();
    get_client_();
  }, [props.customerId]);

  const get_client_ = async () => {
    setLoadData(true);
    const get_emails = await get_email(props.customerId);
    const mget_mail = await get_emails.json();

    if (!get_emails.ok) {
      show_error(setMError, mget_mail);
      setLoadData(false);

      return;
    }
    console.log(mget_mail);
    if (mget_mail.rows.length) {
      setClientEmails(mget_mail.rows);
    }

    const entry = await get_tickets('customers', [
      { type: 'customerId', value: props.customerId },
    ]);
    const mentry = await entry.json();

    if (entry.ok) {
      const mmentry = mentry.customers[0];

      setName(mmentry.CustomerName);
      console.log(mmentry);
    } else {
      setMError('Something went whrong');
    }
    setLoadData(false);
  };

  const update_email_ = async () => {
    if (!name.length || !userName.length || !fullName.length) {
      setMError('Fill all required fields!!');

      return;
    }
    try {
      setInsertting(true);
      if (SelectedRecord === 0) {
        const add_email = await insert_mail({
          CustomerId: props.customerId,
          CustomerEmailName: userName,
        });
        const check_add_email = await add_email.json();

        console.log(check_add_email);
        if (!add_email.ok) {
          throw check_add_email;
        }
        const checkmailserver = await get_mail_server({
          userName: ClientEmails.find(
            (item) => item.CustomerEmailId === SelectedRecord,
          )?.CustomerEmailName,
          domain: 'bmvie.net',
        });

        setLoadData(true);
        if (checkmailserver.ok) {
          await get_client_();
          setMError('s-The domain is existing on Cloudns');
        } else {
          await insert_mail_server_();
        }
      } else if (SelectedMailserverMail === null) {
        await insert_mail_server_();
      } else if (SelectedMailserverMail) {
        const record = {
          email: SelectedMailserverMail.username,
          fullName,
        };

        await update_mail_server(record);
      }
    } catch (e: any) {
      show_error(setMError, e);
      setLoadData(false);
    }
    await get_client_();
    setLoadData(false);
    props.reload((prev: boolean) => !prev);
    setInsertting(false);
  };

  const insert_mail_server_ = async () => {
    setInsertting(true);
    const record = {
      domain: 'bmvie.net',
      userName,
      fullName,
    };
    const insert_mail = await insert_mail_server(record);

    if (insert_mail.ok) {
      setMError('s-Email Added Successfully');
      setInsertting(false);
      await get_client_();
    } else {
      setInsertting(false);
      throw {
        error: 'Error when inserting on Cloudns',
        info: await insert_mail.json(),
      };
    }
  };

  const reset_password = async () => {
    if (SelectedMailserverMail) {
      setInsertting(true);
      const record = {
        email: SelectedMailserverMail.username,
        password: '12345@xyz',
      };
      const updating = await update_mail_server(record);
      const mupdating = await updating.json();

      if (!updating.ok) {
        show_error(setMError, mupdating);
      }
      setInsertting(false);
    }
  };

  const generate_smtp_pass = async () => {
    if (SelectedMailserverMail) {
      setGenerating_smtp_pass(true);
      const record = {
        userName: SelectedMailserverMail.username,
      };
      const get_smtp = await generate_smtp(record);
      const mgetsmtp = await get_smtp.json();

      if (!get_smtp.ok) {
        show_error(setMError, mgetsmtp);
      } else {
        setSmtpValue(mgetsmtp.smtp_pass);
      }
      setGenerating_smtp_pass(false);
    }
  };

  const delete_mail_ = async () => {
    setDeleting(true);
    if (SelectedMailserverMail) {
      console.log(SelectedMailserverMail);
      const record = {
        userName: SelectedMailserverMail.username,
      };
      const delete_mail = await delete_mail_server(record);
      const mdelete_mail = await delete_mail.json();

      if (!delete_mail.ok) {
        show_error(setMError, mdelete_mail);
        setDeleting(false);

        return;
      } else {
        setMError('s-Email deleted successfully from mailserver');
      }
    }
    if (SelectedRecord) {
      console.log(SelectedRecord);
      const delete_mail = await delete_email(SelectedRecord);
      const mdelete_mail = await delete_mail.json();

      if (!delete_mail.ok) {
        show_error(setMError, mdelete_mail);
      } else {
        setMError('s-Email deleted successfully');
        get_client_();
      }
    }
    setDeleting(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          props.setEdit(null);
        }}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                Email License Customer : {props.customerId} {name}
              </ModalHeader>
              <ModalBody>
                {name?.length && !loadData ? (
                  <>
                    <Select
                      defaultSelectedKeys={[String(SelectedRecord)]}
                      label="Select Email"
                      selectionMode="single"
                      value={SelectedRecord}
                      onChange={(e) =>
                        setSelectedRecord(parseInt(e.target.value))
                      }
                    >
                      {[
                        { CustomerEmailId: 0, CustomerEmailName: 'Add new' },
                        ...ClientEmails,
                      ].map((item) => (
                        <SelectItem
                          key={item.CustomerEmailId || '0'}
                          value={item.CustomerEmailId}
                        >
                          {item.CustomerEmailName}
                        </SelectItem>
                      ))}
                    </Select>
                    <Input
                      isRequired
                      endContent={
                        <div className="pointer-events-none flex items-center">
                          <span className="text-default-400">@bmvie.net</span>
                        </div>
                      }
                      isDisabled={SelectedMailserverMail != null}
                      label="Customer UserName"
                      type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <Input
                      isRequired
                      label="Customer Full Name"
                      type="text"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    {SelectedRecord ? (
                      <Tooltip content="Smtp Password">
                        <Snippet
                          aria-label="Smtp Password"
                          codeString={smtpPassword}
                          color="default"
                          symbol={
                            <Tooltip content="regenerate password">
                              <Button
                                isIconOnly
                                isLoading={generating_smtp_pass}
                                radius="full"
                                size="sm"
                                variant="light"
                                onPress={generate_smtp_pass}
                              >
                                <VscDebugRestart />
                              </Button>
                            </Tooltip>
                          }
                        >
                          {smtpPassword.slice(0, 40) +
                            (smtpPassword.length > 40 ? '...' : '')}
                        </Snippet>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                    <div className="grid grid-cols-2 grid-rows-1 items-center justify-center justify-items-center">
                      <div className="grid grid-cols-2">
                        <span>Email State:</span>
                        <Chip>{EmailState}</Chip>
                      </div>
                      <div>
                        <Button
                          isLoading={insertting}
                          size="sm"
                          startContent={<MdOutlineRestartAlt size={20} />}
                          variant="light"
                          onPress={reset_password}
                        >
                          reset password
                        </Button>
                      </div>
                    </div>
                    {SelectedMailserverMail ? (
                      <div className="flex select-none flex-col items-stretch">
                        <Snippet
                          hideSymbol
                          classNames={{
                            pre: 'flex flex-col items-center w-[-webkit-fill-available]',
                          }}
                          codeString="12345@xyz"
                          color="default"
                        >
                          <p className="text-center">
                            default password is:{' '}
                            <span className="font-bold">12345@xyz</span>
                          </p>
                        </Snippet>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <Loading />
                )}
              </ModalBody>
              <ModalFooter>
                <Tooltip content="Save email">
                  <Button
                    isIconOnly
                    isLoading={insertting}
                    onClick={update_email_}
                  >
                    <FaRegSave />
                  </Button>
                </Tooltip>
                {SelectedRecord ? (
                  <Tooltip content="Delete email">
                    <Button
                      isIconOnly
                      isLoading={deleting}
                      onClick={onConfirmOpen}
                    >
                      <MdDeleteForever />
                    </Button>
                  </Tooltip>
                ) : (
                  ''
                )}
                <Tooltip content="Close">
                  <Button isIconOnly onPress={onClose}>
                    <IoMdCloseCircleOutline />
                  </Button>
                </Tooltip>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal isOpen={isConfirmOpen} onOpenChange={onConfirmOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Confirmation</ModalHeader>
              <ModalBody>
                {' '}
                Are you sure?! you are going to delete the record {
                  userName
                } !!{' '}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  isLoading={deleting}
                  onPress={async () => {
                    delete_mail_();
                    onClose();
                  }}
                >
                  Confirm
                </Button>
                <Button onPress={onClose}>Cancel</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
