'use client'
import { restart_app } from '@/client_scripts/fetch_sms_api';
import StoreContext from '@/scripts/storeContext';
import {Button, Tooltip} from '@nextui-org/react';
import { useContext, useState } from 'react';
import { FaPowerOff } from 'react-icons/fa';

export default function RestartAppButton() {
    const [isLoading, setIsLoading] = useState(false);
    const {setMError} = useContext(StoreContext);
    const RestartApp = async () => {
        setIsLoading(true);
        const restart = await restart_app();
        const restartRet = await restart.json();
        if(restart.ok){
            setMError('s-'+restartRet.message);
        }else{
            setMError(restartRet.error);
        }
        setIsLoading(false);
    }
  return (
    <Tooltip content="Restart the app">
        <Button isLoading={isLoading} isIconOnly variant="light" onPress={RestartApp}><FaPowerOff /></Button>
    </Tooltip>
  )
}
