import { Button } from '@nextui-org/button';
import {
  ModalContent,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Checkbox,
} from '@nextui-org/react';
import { useContext, useEffect, useState } from 'react';

import StoreContext from '@/scripts/storeContext';
import {
  get_smsLicense,
  get_tickets,
  update_sms,
} from '@/client_scripts/fetch_tikcets_api';
import { show_error } from '@/client_scripts/functions';

import Loading from '../Loading/Loading';

export default function UpdateSmsForm(props: {
  customerId: number;
  smsLicense: boolean;
  reload: any;
  setEdit: any;
  PID: number;
}) {
  const { onOpen, isOpen, onOpenChange, onClose } = useDisclosure();
  const [name, setName] = useState('');
  const [azureName, setAzureName] = useState('');
  const [insertting, setInsertting] = useState(false);
  const { setMError } = useContext(StoreContext);

  useEffect(() => {
    if (props.customerId === -1) return;
    onOpen();
    get_client_();
  }, [props.customerId]);

  const get_client_ = async () => {
    const check = await get_smsLicense(props.PID);
    const mcheck = await check.json();

    if (!check.ok) {
      setMError('Something went wrong!!');
      console.log(mcheck);

      return;
    }

    if (mcheck.rows.length) {
      const licenseSms = mcheck.rows[0];

      setName(licenseSms.Name);
      setAzureName(
        licenseSms.ContainerReference ? licenseSms.ContainerReference : '',
      );
    } else {
      const entry = await get_tickets('customers', [
        { type: 'customerId', value: props.customerId },
      ]);
      const mentry = await entry.json();

      if (entry.ok) {
        const mmentry = mentry.customers[0];

        setName(mmentry.CustomerName);
        console.log(mmentry);
      } else {
        setMError('Something went whrong');
      }
    }
  };

  const update_entry_ = async () => {
    if (!name.length || !azureName.length || !props.PID) {
      setMError('Fill all required fields!!');

      return;
    }
    try {
      setInsertting(true);
      const license = await update_sms({
        AzureName: azureName,
        CustomerId: props.customerId,
        CustomerName: name,
        IsLicensed: props.smsLicense,
        PublicId: props.PID,
      });

      if (license.ok) {
        props.reload((prev: boolean) => !prev);
        onClose();
        setMError('s-Client licensed successfully');
        props.setEdit(null);
      } else {
        throw await license.json();
      }
    } catch (e: any) {
      show_error(setMError, e);
    }
    props.reload((prev: boolean) => !prev);
    setInsertting(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          props.setEdit(null);
        }}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                Sms License Customer : {props.customerId}
              </ModalHeader>
              <ModalBody>
                {name.length ? (
                  <>
                    <Input
                      isRequired
                      label="Customer Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Input
                      isRequired
                      label="Customer Azure Name"
                      type="text"
                      value={azureName}
                      onChange={(e) => setAzureName(e.target.value)}
                    />
                    <Input
                      label="Public Id"
                      type="number"
                      value={String(props.PID)}
                    />
                    <Checkbox isSelected={props.smsLicense}>
                      {' '}
                      Licensed{' '}
                    </Checkbox>
                  </>
                ) : (
                  <Loading />
                )}
              </ModalBody>
              <ModalFooter>
                <Button isLoading={insertting} onClick={update_entry_}>
                  Save
                </Button>
                <Button onPress={onClose}>Close</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
