'use client';

import { NextUIProvider } from '@nextui-org/system';
import { useRouter } from 'next/navigation';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { ThemeProviderProps } from 'next-themes/dist/types';
import { I18nextProvider } from 'react-i18next';

import StoreProvider from '@/components/StoreProvider/StoreProvider';
import i18n from '@/translations';

export interface ProvidersProps {
  children: React.ReactNode;
  themeProps?: ThemeProviderProps;
  RoleId: number;
}

// const [editTicket, setEditTicket] = useState(-1)

export function Providers({ children, themeProps, RoleId }: ProvidersProps) {
  const router = useRouter();

  return (
    <NextUIProvider navigate={router.push} onError={(e) => console.log(e)}>
      <NextThemesProvider {...themeProps}>
        <I18nextProvider i18n={i18n}>
          <StoreProvider RoleId={RoleId}>{children}</StoreProvider>
        </I18nextProvider>
      </NextThemesProvider>
    </NextUIProvider>
  );
}
