import { CalendarDateTime } from '@internationalized/date';

export const DateToCalendar = (
  date: Date | undefined,
  time: boolean = true,
) => {
  if (date?.getDate()) {
    const mydate = new CalendarDateTime(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      time ? date.getHours() - 1 : undefined,
      time ? date.getMinutes() : undefined,
    );

    return mydate;
  }

  return undefined;
};

export function shallow<T extends object>(source: T): T {
  const copy = {} as T;

  Object.keys(source).forEach((key) => {
    copy[key as keyof T] = source[key as keyof T];
  });

  return copy;
}

export const toCurrency = (curr: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'DZD',
  }).format(curr);
};
export const toDecimal = (curr: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(curr);
};

export const get_settings = async (key: string) => {
  const res = await fetch('/api/settings/get_settings', {
    method: 'POST',
    body: JSON.stringify({
      key,
    }),
  });

  return { data: await res.json(), ok: res.ok };
};
export const get_next = async () => {
  const res = await fetch('/api/settings/get_next', {
    method: 'POST',
  });

  return { data: await res.json(), ok: res.ok };
};
export const set_settings = async (key: string, value: string) => {
  const res = await fetch('/api/settings/set_settings', {
    method: 'POST',
    body: JSON.stringify({
      key,
      value,
    }),
  });

  return { data: await res.json(), ok: res.ok };
};

export const show_error = (setMError: any, error: any) => {
  setMError('Something went wrong: ' + error?.error);
  console.error(error);
};
