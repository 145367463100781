export const open_anydesk = (id: string, password: string):Promise<{error:string} | {info:string}> => {
  return new Promise((resolve, reject) => {
    const BMTOOL_HOST = 'ws://localhost';
    const BMTOOL_PORT = 32493;

    console.log(`${BMTOOL_HOST}:${BMTOOL_PORT}`);
    const ws = new WebSocket(`${BMTOOL_HOST}:${BMTOOL_PORT}`);

    ws.onopen = () => {
      console.log('connected succesfully');
      resolve({info:'connected successfully'});
      ws.send(
        JSON.stringify({
          command: 'RCC2',
          params: {
            script: `echo ${password} | "C:\\Program Files (x86)\\AnyDesk\\AnyDesk.exe" ${id} --with-password`,
          },
        }),
      );
    };

    ws.onerror = () => {
      alert('Check BmOutils!!!');
      resolve({error:'Check BmOutils!!'});
    };
  });
};
