// components/MultiSelect.js
import { useId, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { nextui } from '@nextui-org/react';

import styles from './CustomSelect.module.css';

interface CustomOption {
  value: string | number;
  label: string;
}

export default function CustomSelect(props: {
  Label?: string;
  className?: string;
  options: any;
  selectedOptions: any;
  setSelectedOptions: any;
  placeholder?: string;
  createable?: boolean;
  isMulti?: boolean;
  baseClass?: string;
}) {
  // Custom styles for react-select using NextUI theme
  const customStyles = (theme: any): StylesConfig<CustomOption, true> => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: theme.colors['default-100']({}),
      borderColor: theme.colors['default-100']({}),
      minHeight: '38px',
      paddingTop: props.Label ? '10px' : undefined,
      borderRadius: theme.borderRadius.small,

      transition: theme.transitionDuration['DEFAULT'],
      '&:hover ': {
        borderColor: theme.colors['default-200']({}),
        backgroundColor: theme.colors['default-200']({}),
        '.select__input-container, input': {
          backgroundColor: theme.colors['default-200']({}),
        },
      },
      '&:active': {},
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.colors.background({}),
      zIndex: 100,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: theme.fontSize.tiny[0],
      textAlign: 'center',
      backgroundColor: state.isSelected
        ? theme.colors.primary({})
        : state.isFocused
          ? theme.colors['primary-400']({})
          : theme.colors.background({}),
      color: theme.colors['foreground']({}),
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: theme.colors['default']({}),
      borderRadius: theme.borderRadius.large,
      color: theme.colors['foreground']({}),
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: theme.colors['foreground']({}),
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colors['foreground']({}),
      zIndex: 0,
      fontSize: theme.fontSize.tiny[0],
    }),
    input: (provided) => ({
      ...provided,
      color: theme.colors['foreground']({}),
      backgroundColor: theme.colors['default-100']({}),
      transition: theme.transitionDuration['DEFAULT'],
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: theme.colors['default-500']({}), // Change the placeholder color
      fontSize: theme.fontSize.tiny[0],
      fontWeight: 'bold',
      zIndex: 0,
    }),

    multiValueRemove: (provided) => ({
      ...provided,
      color: theme.colors['foreground']({}),
      borderRadius: theme.borderRadius.large,
      '&:hover': {
        backgroundColor: theme.colors['default']({}),
        fontWeight: 'bold',
      },
    }),
  });

  const theme = nextui().config?.theme?.extend;

  // console.log(theme)
  const [isFocused, setIsFocused] = useState(
    props.selectedOptions ? true : false,
  );

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    !props.selectedOptions ? setIsFocused(false) : '';
  };
  const handleChange = (selected: any) => {
    props.setSelectedOptions(selected);
  };
  const handleCreate = (inputValue: any) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };

    handleChange([...props.selectedOptions, newOption]);
  };

  // debugger;
  return (
    <div
      className={
        !props.Label
          ? ''
          : `${styles['floating-label-select']} ${isFocused || props.selectedOptions ? styles.focused : ''} ${props.baseClass}`
      }
    >
      {props.Label ? (
        <label
          className={`rounded-lg text-small text-default-500 ${styles['floating-label']}`}
        >
          {props.Label}
        </label>
      ) : (
        ''
      )}
      {!props.createable && (
        <Select
          className={`${props.className}`}
          classNamePrefix="select"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
          instanceId={useId()}
          isMulti={props.isMulti ? true : undefined}
          options={props.options}
          placeholder={props.Label ? '' : props.placeholder}
          styles={customStyles(theme)}
          value={props.selectedOptions}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      )}
      {props.createable && (
        <CreatableSelect
          className={props.className}
          classNamePrefix="select"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
          isMulti={props.isMulti ? true : undefined}
          options={props.options}
          placeholder={props.placeholder}
          styles={customStyles(theme)}
          value={props.selectedOptions}
          onChange={handleChange}
          onCreateOption={handleCreate}
        />
      )}
    </div>
  );
}
