import { Button } from '@nextui-org/button';
import {
  ModalContent,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { useContext, useEffect, useState } from 'react';

import StoreContext from '@/scripts/storeContext';
import { get_enteries, update_entry } from '@/client_scripts/fetch_tikcets_api';

import Loading from '../Loading/Loading';

export default function EditEntry(props: {
  entryId: number;
  reload: any;
  setEdit: any;
}) {
  const { onOpen, isOpen, onOpenChange, onClose } = useDisclosure();
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [type, setType] = useState<number>();
  const [password, setPassword] = useState('');
  const [insertting, setInsertting] = useState(false);
  const { setMError } = useContext(StoreContext);

  useEffect(() => {
    if (props.entryId === -1) return;
    onOpen();
    get_entry_();
  }, [props.entryId]);

  const get_entry_ = async () => {
    const entry = await get_enteries(undefined, props.entryId);
    const mentry = await entry.json();

    if (entry.ok) {
      const mmentry = mentry.enteries[0];

      setName(mmentry.EntryName);
      setType(mmentry.EntryType);
      setPassword(mmentry.Password);
      setValue(mmentry.ID);
      console.log(mmentry.EntryType);
    } else {
      setMError('Something went whrong');
    }
  };

  const update_entry_ = async () => {
    if (!name.length || !value.length || type == null) {
      setMError('Fill all required fields!!');

      return;
    }
    try {
      setInsertting(true);
      const id = await update_entry({
        id: props.entryId,
        name,
        value,
        type: type as 0 | 1 | 2 | 6,
        password,
        customer: -1,
      });
      const mid = await id.json();

      if (id.ok) {
        setMError('s-Entry updated successfully');
        props.setEdit(-1);
        onClose();
      } else {
        setMError('Someting went wrong');
        console.error(mid);
      }
      console.log(mid);
    } catch {}
    props.reload((prev: boolean) => !prev);
    setInsertting(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          props.setEdit(-1), onClose();
        }}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Edit Entry {props.entryId}</ModalHeader>
              <ModalBody>
                {name.length ? (
                  <>
                    <Input
                      isRequired
                      label="Entry Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Select
                      isRequired
                      defaultSelectedKeys={[String(type)]}
                      label="Entry type"
                      value={type}
                      onChange={(e) => setType(parseInt(e.target.value))}
                    >
                      <SelectItem key={0} value={0}>
                        Anydesk
                      </SelectItem>
                      <SelectItem key={1} value={1}>
                        Rustdesk
                      </SelectItem>
                      <SelectItem key={2} value={2}>
                        Password
                      </SelectItem>
                      <SelectItem key={6} value={6}>
                        TeamViewer
                      </SelectItem>
                    </Select>
                    <Input
                      isRequired
                      label="Entry value"
                      type="text"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                    <Input
                      label="Entry password"
                      type="text"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </>
                ) : (
                  <Loading />
                )}
              </ModalBody>
              <ModalFooter>
                <Button isLoading={insertting} onClick={update_entry_}>
                  Save
                </Button>
                <Button onPress={onClose}>Close</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
