export const get_sms_tasks = async (
  filters: { type: string; value: any }[],
) => {
  return await fetch('/api/sms/get_sms_tasks', {
    method: 'POST',
    body: JSON.stringify({
      filters,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const get_sms_services = async (serviceId?: number) => {
  return await fetch('/api/sms/get_services_state', {
    method: 'POST',
    body: JSON.stringify({
      serviceId
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const get_failed_sms = async () => {
  return await fetch('/api/sms/get_failed_count', {
    method: 'POST',
  })
}

export const toggle_service = async (service: string, action: string) => {
  return await fetch('/api/sms/service_actions', {
    method: 'POST',
    body: JSON.stringify({
      service, action
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const update_service = async (Service: Partial<SmsService>) => {
  return await fetch('/api/sms/update_service_port', {
    method: 'POST',
    body: JSON.stringify({
      Service
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const update_failed = async (Days: number = 0) => {
  return await fetch('/api/sms/update_failed', {
    method: 'POST',
    body: JSON.stringify({
      Days
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const restart_app = async () => {
  return await fetch("https://remoteapitest.bmvie.net/restart");
}