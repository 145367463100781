export const get_email = async (
  CustomerId?: number,
  EmailId?: number,
  EmailName?: string,
) => {
  return await fetch('/api/email/get_email', {
    method: 'POST',
    body: JSON.stringify({
      CustomerId,
      EmailId,
      EmailName,
    }),
  });
};
export const delete_email = async (Email: number) => {
  return await fetch('/api/email/delete_email', {
    method: 'POST',
    body: JSON.stringify({
      Email,
    }),
  });
};
export const update_mail = async (Email: Partial<customer_email>) => {
  return await fetch('/api/email/update_email', {
    method: 'POST',
    body: JSON.stringify({
      Email,
    }),
  });
};

export const insert_mail = async (Email: Partial<customer_email>) => {
  return await fetch('/api/email/insert_email', {
    method: 'POST',
    body: JSON.stringify({
      Email,
    }),
  });
};

export const get_mail_server = async (Email: any) => {
  return await fetch('/api/email/get_mail_server', {
    method: 'POST',
    body: JSON.stringify({
      Email,
    }),
  });
};
export const delete_mail_server = async (Email: any) => {
  return await fetch('/api/email/delete_mail_server', {
    method: 'POST',
    body: JSON.stringify({
      Email,
    }),
  });
};
export const update_mail_server = async (Email: any) => {
  return await fetch('/api/email/update_mail_server', {
    method: 'POST',
    body: JSON.stringify({
      Email,
    }),
  });
};

export const insert_mail_server = async (Email: any) => {
  return await fetch('/api/email/insert_mail_server', {
    method: 'POST',
    body: JSON.stringify({
      Email,
    }),
  });
};

export const generate_smtp = async (Email: any) => {
  return await fetch('/api/email/generate_smtp', {
    method: 'POST',
    body: JSON.stringify({
      Email,
    }),
  });
};
