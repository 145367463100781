export const get_tickets = async (
  type: string = 'tickets',
  filters: any[] = [
    { type: 'page', value: '0' },
    { type: 'state', value: 0 },
  ],
) => {
  return await fetch('/api/get_tickets/get_tickets', {
    method: 'POST',
    body: JSON.stringify({
      filters: filters,
      object: type,
    }),
  });
};

export const get_enteries = async (customer?: string, entry?: number) => {
  return await fetch('/api/enteries/get_enteries', {
    method: 'POST',
    body: JSON.stringify({
      customer: customer,
      entry: entry,
    }),
  });
};

export const get_audit = async (
  ticketId: number | string,
  table: 'Ticket' | 'RapportIntervention' = 'Ticket',
) => {
  return await fetch('/api/get_tickets/get_audit', {
    method: 'POST',
    body: JSON.stringify({
      ticketId,
      table,
    }),
  });
};

export const get_content = async (refId?: number | string, type?: boolean) => {
  return await fetch('/api/get_tickets/get_content', {
    method: 'POST',
    body: JSON.stringify({
      refId,
      type,
    }),
  });
};

export const get_attach = async (ticketId: number | string) => {
  return await fetch('/api/attach/get_attach', {
    method: 'POST',
    body: JSON.stringify({
      ticketId,
    }),
  });
};
export const get_user = async (userId: number | string) => {
  return await fetch('/api/user/get_user', {
    method: 'POST',
    body: JSON.stringify({
      userId,
    }),
  });
};

export const get_phones = async (
  customerId?: number | string,
  tel?: string,
) => {
  return await fetch('/api/get_tickets/get_phones', {
    method: 'POST',
    body: JSON.stringify({
      customerId,
      tel,
    }),
  });
};

export const get_smsLicense = async (pid: number) => {
  return await fetch('/api/customer/get_license_sms', {
    method: 'POST',
    body: JSON.stringify({
      pid,
    }),
  });
};

export const update_ticket = async (oldTicket: ticket, newTicket: ticket) => {
  return await fetch('/api/get_tickets/update_ticket', {
    method: 'POST',
    body: JSON.stringify({
      oldTicket,
      newTicket,
    }),
  });
};

export const update_types = async (id: number, type: string, value: string) => {
  return await fetch('/api/get_tickets/update_types', {
    method: 'POST',
    body: JSON.stringify({
      id,
      type,
      value,
    }),
  });
};

export const update_customer = async (
  oldCustomer: customer,
  newCustomer: customer,
) => {
  return await fetch('/api/customer/update_customer', {
    method: 'POST',
    body: JSON.stringify({
      oldCustomer,
      newCustomer,
    }),
  });
};

export const update_sms = async (sms: smsForm) => {
  return await fetch('/api/customer/license_sms', {
    method: 'POST',
    body: JSON.stringify({
      sms,
    }),
  });
};

export const update_entry = async (newEntry: entry) => {
  return await fetch('/api/enteries/update_entry', {
    method: 'POST',
    body: JSON.stringify({
      entry: newEntry,
    }),
  });
};

export const insert_ticket = async (newTicket: ticket) => {
  return await fetch('/api/get_tickets/insert_ticket', {
    method: 'POST',
    body: JSON.stringify({
      newTicket,
    }),
  });
};

export const insert_phones = async (
  customerId: number | string,
  phones: string[],
) => {
  return await fetch('/api/get_tickets/insert_phone', {
    method: 'POST',
    body: JSON.stringify({
      customerId,
      phones,
    }),
  });
};

export const insert_types = async (
  type: string,
  values: string[],
  refId?: number,
) => {
  return await fetch('/api/get_tickets/insert_type', {
    method: 'POST',
    body: JSON.stringify({
      type,
      refId,
      values,
    }),
  });
};
export const insert_customer = async (customer: customer) => {
  return await fetch('/api/customer/insert_customer', {
    method: 'POST',
    body: JSON.stringify({
      customer,
    }),
  });
};
export const insert_entry = async (entry: entry) => {
  return await fetch('/api/enteries/insert_entry', {
    method: 'POST',
    body: JSON.stringify({
      entry,
    }),
  });
};

export const delete_entry = async (entry: number) => {
  return await fetch('/api/enteries/delete_entry', {
    method: 'POST',
    body: JSON.stringify({
      entry,
    }),
  });
};

export const delete_customer = async (customerId: number) => {
  return await fetch('/api/customer/delete_cutomer', {
    method: 'POST',
    body: JSON.stringify({
      customerId,
    }),
  });
};

export const upload_file = async (file: File, ticketId: string) => {
  const formdata = new FormData();

  formdata.append('file', file);
  formdata.append('ticketId', ticketId);

  return await fetch('/api/upload', {
    method: 'POST',
    body: formdata,
  });
};

export const download_file = async (file_name: string) => {
  return await fetch('/api/attach/get_file', {
    method: 'POST',
    body: JSON.stringify({
      file_name,
    }),
  });
};
export const delete_file = async (file_name: string) => {
  return await fetch('/api/attach/delete_file', {
    method: 'POST',
    body: JSON.stringify({
      file_name,
    }),
  });
};
