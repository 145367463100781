export const operations = [
  'InstallationLogiciel',
  'MiseEnMarche',
  'Assistance',
  'InstallationAutomate',
  'Formation',
];
export const logiciels = ['BmVie', 'BmLab'];
export const invoiceState = ['Unpaid', 'Partial', 'Paid', 'Late'];
export const invoiceStateStyles = ['danger', 'primary', 'success', 'warning'];
export const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'Decembere',
];
