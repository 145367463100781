import { useContext, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/table';
import { Button } from '@nextui-org/button';
import {
  Checkbox,
  Pagination,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from '@nextui-org/react';
import { MdCancel, MdDeleteForever } from 'react-icons/md';
import { GiConfirmed } from 'react-icons/gi';
import { FaEdit } from 'react-icons/fa';
import { t } from 'i18next';

import { delete_customer } from '@/client_scripts/fetch_tikcets_api';
import CustomSelect from '@/elements/CustomSelect/CustomSelect';
import StoreContext from '@/scripts/storeContext';

import NewClientForm from '../NewClientForm/NewClientForm';
import EditClient from '../EditClient/EditClient';
import UpdateSmsForm from '../UpdateSmsForm/UpdateSmsForm';
import DnsForm from '../DnsForm/DnsForm';
import EmailForm from '../EmailForm/EmailForm';

import styles from './CustomersTable.module.css';

export default function CustomersTable(props: {
  RoleId: number;
  className?: string;
  onReload: any;
}) {
  const { customers, setSelectedCustomer, setMError, setReload } =
    useContext(StoreContext);

  const [nameFilter, setNameFilter] = useState<selectLabel[]>([]);
  const [pidFilter, setPidFilter] = useState<selectLabel[]>([]);
  const [smsFilter, setSmsFilter] = useState(false);
  const [dnsFilter, setDnsFilter] = useState(false);
  const [emailFilter, setEmailFilter] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [filtredCustomers, setFiltredCustomers] = useState<any>([]);

  const [onEdit, setOnEdit] = useState(-1);

  const [mchange, setMchange] = useState(false);

  const [openPopOver, setOpenPopOver] = useState(-1);
  const [onDelete, setOnDelete] = useState(-1);

  const [updateSms, setUpdateSms] = useState<any>(null);
  const [updateDns, setUpdateDns] = useState<any>(null);
  const [updateEmail, setUpdateEmail] = useState<any>(null);

  useEffect(() => {
    if (mchange) {
      setMchange(false);

      return;
    }
    setCurrPage(1);
    const nids = nameFilter.map((item: any) => item.value);

    const selcusts = customers.filter((item: any) => {
      return nids.includes(item.CustomerId);
    });

    setPidFilter(
      selcusts.map((item: any) => {
        return { value: item.CustomerId, label: item.PublicId };
      }),
    );
    setMchange(true);
  }, [nameFilter]);

  useEffect(() => {
    if (mchange) {
      setMchange(false);

      return;
    }
    setCurrPage(1);
    const nids = pidFilter.map((item: any) => item.value);

    const selcusts = customers.filter((item: any) => {
      return nids.includes(item.CustomerId);
    });

    setNameFilter(
      selcusts.map((item: any) => {
        return { value: item.CustomerId, label: item.CustomerName };
      }),
    );
    setMchange(true);
  }, [pidFilter]);

  useEffect(() => {
    if (updateSms === null) {
      setReload((prev: boolean) => !prev);
    }
  }, [updateSms]);

  useEffect(() => {
    const filtring = customers?.filter(
      (item: any) =>
        (nameFilter.length
          ? nameFilter.map((item) => item.label).includes(item.CustomerName)
          : true) &&
        (smsFilter ? item.Licensed : true) &&
        (dnsFilter ? item.dnsCount > 0 : true) &&
        (emailFilter ? item.emailCount > 0 : true),
    );

    setFiltredCustomers(filtring);
  }, [nameFilter, smsFilter, customers, dnsFilter, emailFilter]);

  return (
    <>
      <Table
        isHeaderSticky
        aria-label="Example table with custom cells"
        bottomContent={
          <div className="sticky bottom-0 flex items-center justify-between px-2 py-2">
            <span className="w-[30%] text-small font-semibold text-default-700">
              {currPage * 20 >= filtredCustomers.length
                ? 'All items selected ' + filtredCustomers.length
                : `${currPage * 20} of ${filtredCustomers.length} selected`}
            </span>
            <Pagination
              isCompact
              showControls
              showShadow
              color="primary"
              page={currPage}
              total={Math.ceil(
                filtredCustomers?.filter((item: any) => {
                  if (nameFilter.length)
                    return nameFilter
                      .map((mitem: any) => mitem.label)
                      .includes(item.CustomerName) && smsFilter
                      ? item.Licensed
                      : true;

                  return true && smsFilter ? item.Licensed : true;
                }).length / 20,
              )}
              onChange={(page) => setCurrPage(page)}
            />
            <div className="hidden w-[30%] justify-end gap-2 sm:flex" />
          </div>
        }
        className="animate-tada"
        classNames={{
          base: ` overflow-auto hide-scroller hide-scroller-child ${styles.table_body}`,
        }}
      >
        <TableHeader>
          <TableColumn className="text-center">delete/edit</TableColumn>
          <TableColumn className="text-center">ID</TableColumn>
          <TableColumn className="text-center">Name</TableColumn>
          <TableColumn className="text-center">Public ID</TableColumn>
          <TableColumn className="text-center">SMS</TableColumn>
          <TableColumn className="text-center">DNS</TableColumn>
          <TableColumn className="text-center">Email</TableColumn>
          <TableColumn className="text-center">Show</TableColumn>
        </TableHeader>
        <TableBody emptyContent="Empty">
          <TableRow key="new">
            <TableCell className="text-center">
              <NewClientForm setReload={props.onReload} />
            </TableCell>
            <TableCell>
              <div />
            </TableCell>
            <TableCell>
              <CustomSelect
                isMulti
                className={`form-control border-0`}
                options={customers.map((item: any) => {
                  return { value: item.CustomerId, label: item.CustomerName };
                })}
                selectedOptions={nameFilter}
                setSelectedOptions={setNameFilter}
              />
            </TableCell>
            <TableCell>
              <CustomSelect
                isMulti
                className={`form-control border-0`}
                options={customers
                  .filter((item: any) => item.PublicId)
                  .map((item: any) => {
                    return { value: item.CustomerId, label: item.PublicId };
                  })}
                selectedOptions={pidFilter}
                setSelectedOptions={setPidFilter}
              />
            </TableCell>
            <TableCell className="text-center">
              <Checkbox
                isSelected={smsFilter}
                onChange={(e) => {
                  setCurrPage(1);
                  setSmsFilter(e.target.checked);
                }}
              />
            </TableCell>
            <TableCell className="text-center">
              <Checkbox
                isSelected={dnsFilter}
                onChange={(e) => {
                  setCurrPage(1);
                  setDnsFilter(e.target.checked);
                }}
              />
            </TableCell>
            <TableCell className="text-center">
              <Checkbox
                isSelected={emailFilter}
                onChange={(e) => {
                  setCurrPage(1);
                  setEmailFilter(e.target.checked);
                }}
              />
            </TableCell>
            <TableCell>
              <div />
            </TableCell>
          </TableRow>
          {filtredCustomers
            .slice((currPage - 1) * 20, currPage * 20)
            .map((item: any) => (
              <TableRow key={item.CustomerId}>
                <TableCell className="flex justify-center text-center">
                  {props.RoleId === 1 ? (
                    <Popover
                      key={item.NumTel}
                      showArrow
                      backdrop="transparent"
                      isOpen={openPopOver == item.CustomerId}
                      offset={10}
                      placement="top"
                    >
                      <PopoverTrigger>
                        <Button
                          isIconOnly
                          className="capitalize"
                          isDisabled={onDelete != -1}
                          isLoading={onDelete == item.CustomerId}
                          radius="full"
                          size="sm"
                          variant="light"
                          onClick={() =>
                            setOpenPopOver((prev) =>
                              prev != -1 ? -1 : item.CustomerId,
                            )
                          }
                        >
                          <MdDeleteForever />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="flex flex-row">
                        <Tooltip content={`Delete`}>
                          <Button
                            isIconOnly
                            color="danger"
                            radius="full"
                            size="sm"
                            variant="light"
                            onPress={async () => {
                              setOnDelete(item.CustomerId);
                              const delete_ent = await delete_customer(
                                item.CustomerId,
                              );

                              if (!delete_ent.ok) {
                                setMError(
                                  'Error accured when deleting customer!',
                                );
                              } else {
                                setReload((prev: boolean) => !prev);
                              }
                              setOnDelete(-1);
                            }}
                          >
                            <GiConfirmed />
                          </Button>
                        </Tooltip>
                        <Tooltip content={`Cancel`}>
                          <Button
                            isIconOnly
                            radius="full"
                            size="sm"
                            variant="light"
                            onClick={() => setOpenPopOver(-1)}
                          >
                            <MdCancel />
                          </Button>
                        </Tooltip>
                      </PopoverContent>
                    </Popover>
                  ) : (
                    ''
                  )}
                  <Tooltip content="Edit">
                    <Button
                      isIconOnly
                      radius="full"
                      size="sm"
                      variant="light"
                      onPress={() => {
                        setOnEdit(item.CustomerId);
                      }}
                    >
                      <FaEdit />
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell className="text-center">{item.CustomerId}</TableCell>
                <TableCell>{item.CustomerName}</TableCell>
                <TableCell className="text-center">{item.PublicId}</TableCell>
                <TableCell className="text-center">
                  <Checkbox
                    isSelected={item.Licensed}
                    onChange={(e) => {
                      console.log(item);
                      item.PublicId
                        ? setUpdateSms({
                            customer: item.CustomerId,
                            License: !item.Licensed,
                            PublicId: item.PublicId,
                          })
                        : setMError('set Customer Public Id');
                    }}
                  />
                </TableCell>
                <TableCell className="text-center">
                  <Checkbox
                    isSelected={item.dnsCount > 0}
                    onChange={(e) => {
                      console.log(item);
                      setUpdateDns({ customer: item.CustomerId });
                    }}
                  />
                </TableCell>
                <TableCell className="text-center">
                  <Checkbox
                    isSelected={item.emailCount > 0}
                    onChange={(e) => {
                      console.log(item);
                      setUpdateEmail({ customer: item.CustomerId });
                    }}
                  />
                </TableCell>
                <TableCell className="text-center">
                  <Tooltip content={t('Entries')}>
                    <Button
                      color="success"
                      onClick={() => setSelectedCustomer(item.CustomerId)}
                    >
                      {t('Show')}
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {onEdit != -1 ? (
        <EditClient CustomerId={onEdit} setEdit={setOnEdit} />
      ) : (
        ''
      )}
      {updateSms ? (
        <UpdateSmsForm
          PID={updateSms.PublicId}
          customerId={updateSms.customer}
          reload={setReload}
          setEdit={setUpdateSms}
          smsLicense={updateSms.License}
        />
      ) : (
        ''
      )}
      {updateDns ? (
        <DnsForm
          customerId={updateDns.customer}
          reload={setReload}
          setEdit={setUpdateDns}
        />
      ) : (
        ''
      )}
      {updateEmail ? (
        <EmailForm
          customerId={updateEmail.customer}
          reload={setReload}
          setEdit={setUpdateEmail}
        />
      ) : (
        ''
      )}
    </>
  );
}
