import { Button } from '@nextui-org/button';
import {
  ModalContent,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { useContext, useState } from 'react';
import { RiPlayListAddLine } from 'react-icons/ri';

import StoreContext from '@/scripts/storeContext';
import { insert_entry } from '@/client_scripts/fetch_tikcets_api';

export default function NewEnteryForm(props: {
  customer: string | number;
  reload: any;
}) {
  const { onOpen, isOpen, onOpenChange, onClose } = useDisclosure();
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [type, setType] = useState<number>(0);
  const [password, setPassword] = useState('');
  const [insertting, setInsertting] = useState(false);
  const { setMError } = useContext(StoreContext);

  const insert_entry_ = async () => {
    if (!name.length || !value.length || type == null) {
      setMError('Fill all required fields!!');

      return;
    }
    try {
      setInsertting(true);
      const id = await insert_entry({
        name,
        value,
        type: type as 0 | 1 | 2 | 6,
        password,
        customer: props.customer,
      });
      const mid = await id.json();

      if (id.ok) {
        setMError('s-Entry inserted successfully');
        onClose();
      } else {
        setMError(mid.error ? mid.error : 'Someting went wrong');
      }
    } catch {}
    props.reload((prev: boolean) => !prev);
    setInsertting(false);
  };

  return (
    <>
      <Button isIconOnly size="sm" variant="flat" onPress={onOpen}>
        <RiPlayListAddLine />
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Add new entry</ModalHeader>
              <ModalBody>
                <Input
                  isRequired
                  label="Entry Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Select
                  isRequired
                  defaultSelectedKeys={[0]}
                  label="Entry type"
                  value={type}
                  onChange={(e) => setType(parseInt(e.target.value))}
                >
                  <SelectItem key={0} value={0}>
                    Anydesk
                  </SelectItem>
                  <SelectItem key={1} value={1}>
                    Rustdesk
                  </SelectItem>
                  <SelectItem key={2} value={2}>
                    Password
                  </SelectItem>
                  <SelectItem key={6} value={6}>
                    TeamViewer
                  </SelectItem>
                </Select>
                <Input
                  isRequired
                  label="Entry value"
                  type="text"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
                <Input
                  label="Entry password"
                  type="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button isLoading={insertting} onClick={insert_entry_}>
                  Save
                </Button>
                <Button onPress={onClose}>Close</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
