import {
  ModalContent,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Button,
} from '@nextui-org/react';
import { useContext, useEffect, useState } from 'react';

import {
  get_tickets,
  update_customer,
} from '@/client_scripts/fetch_tikcets_api';
import StoreContext from '@/scripts/storeContext';
import { Months } from '@/client_scripts/constants';
import CustomSelect from '@/elements/CustomSelect/CustomSelect';

import Loading from '../Loading/Loading';

export default function EditClient(props: {
  CustomerId: number;
  setEdit: any;
}) {
  const { onOpen, isOpen, onOpenChange, onClose } = useDisclosure();
  const [name, setName] = useState('');
  const [wilaya, setWilaya] = useState('');
  const [pid, setPid] = useState('');
  const [origin, setOrigin] = useState<any>(null);
  const [email, setEmail] = useState('');
  const [licenseMonth, setLicenseMonth] = useState<{
    label: string;
    value: MonthNumber;
  }>({ label: 'January', value: 1 });
  const [insertting, setInsertting] = useState(false);
  const { setMError, setReload } = useContext(StoreContext);

  useEffect(() => {
    if (!props.CustomerId || props.CustomerId === -1) {
      setName('');
      setWilaya('');
      setPid('');

      return;
    }
    onOpen();
    _get_customer();
  }, [props.CustomerId]);

  const _update_customer = async () => {
    setInsertting(true);
    const oldCustomer: customer = {
      id: origin.CustomerId,
      name: origin.CustomerName,
      pid: origin.PublicId,
      wilaya: origin.Wilaya,
      licenseMonth: origin.LicenseMonth,
      email: origin.Email,
    };
    const newCustomer: customer = {
      id: origin.CustomerId,
      name: name,
      pid: pid,
      wilaya: wilaya,
      email,
      licenseMonth: licenseMonth.value,
    };
    const update_c = await update_customer(oldCustomer, newCustomer);
    const mupdate = await update_c.json();

    if (update_c.ok) {
      setMError('s-Client Updated Successfully!!');
      setReload((prev: boolean) => !prev);
      props.setEdit(-1);
      onClose();
    } else {
      setMError('Something whent Wrong');
      console.error(mupdate);
    }
    setInsertting(false);
  };

  const _get_customer = async () => {
    const customer = await get_tickets('customers', [
      { type: 'customerId', value: props.CustomerId },
    ]);
    const mcustomer = await customer.json();

    if (customer.ok) {
      const mmcustomer = mcustomer.customers[0];

      console.log(mmcustomer);
      setName(mmcustomer.CustomerName);
      setPid(mmcustomer.PublicId ? mmcustomer.PublicId : '');
      setWilaya(mmcustomer.Wilaya ? mmcustomer.Wilaya : '');
      setLicenseMonth(
        mmcustomer.LicenseMonth
          ? {
              value: mmcustomer.LicenseMonth,
              label: Months[mmcustomer.LicenseMonth - 1],
            }
          : { label: 'January', value: 1 },
      );
      setEmail(mmcustomer.Email ? mmcustomer.Email : '');
      setOrigin(mmcustomer);
    } else {
      setMError(`Can't get client`);
      console.error(mcustomer);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          props.setEdit(-1);
          onClose();
        }}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Edit customer</ModalHeader>
              <ModalBody>
                {name.length ? (
                  <>
                    <Input
                      label="Customer Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Input
                      label="Wilaya code"
                      max="58"
                      min="1"
                      type="number"
                      value={wilaya}
                      onChange={(e) => setWilaya(e.target.value)}
                    />
                    <Input
                      label="Public Id"
                      min={1}
                      type="number"
                      value={pid}
                      onChange={(e) => setPid(e.target.value)}
                    />
                    <Input
                      label="Email"
                      min={1}
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <CustomSelect
                      Label="Licence Month"
                      options={Months.map((item, index) => ({
                        label: item,
                        value: index + 1,
                      }))}
                      placeholder="Customer licence month"
                      selectedOptions={licenseMonth}
                      setSelectedOptions={setLicenseMonth}
                    />
                  </>
                ) : (
                  <Loading />
                )}
              </ModalBody>
              <ModalFooter>
                <Button isLoading={insertting} onClick={_update_customer}>
                  Save
                </Button>
                <Button onPress={onClose}>Close</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}
