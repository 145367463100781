'use client';
import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { Snippet } from '@nextui-org/snippet';

import StoreContext from '@/scripts/storeContext';
import { get_tickets } from '@/client_scripts/fetch_tikcets_api';

import CustomersTable from '../CustomersForm/CustomersTable';
import EnteriesTable from '../EnteriesTable/EnteriesTable';
import Loading from '../Loading/Loading';

export default function Providers({
  children,
  RoleId,
}: Readonly<{
  children: React.ReactNode;
  RoleId: number;
}>) {
  const pathname = usePathname();

  // table variables
  const [tickets, setTickets] = useState<ticket[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [types, setTypes] = useState<any[]>([]);
  const [customers, setCustomers] = useState<any[]>([]);
  const [telephones, setTelephones] = useState<any[]>([]);
  const periorities = ['LOW', 'MEDIUM', 'HIGH', 'URGENT'];
  const status = ['OPEN', 'WAIT', 'DEVELOPEMENT', 'RESOLVED', 'CLOSED'];
  const [reload, setReload] = useState(false);

  // clients variables
  const [showClients, setShowClients] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(-1);
  const [selCustomer, setSelCustomer] = useState<any>(null);

  //edit ticket
  const [editTicket, setEditTicket] = useState(-1);
  const [selState, setSelState] = useState<string | number>(-1);

  //new ticket
  const [NewTicket, setNewTicket] = useState(false);

  //Enteries
  const [enteries, setEnteries] = useState<any[]>([]);
  const [gettingEntries, setGettingEntries] = useState(false);

  //error
  const [mError, setMError] = useState('');

  // theme
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    document.body.classList.remove('bg-dark', 'bg-light');
    document.body.classList.add(`bg-${theme}`);
  }, [theme]);

  let timeout: NodeJS.Timeout;

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setMError('');
    }, 5000);
  }, [mError]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersResponse, typesResponse, customersResponse] =
          await Promise.all([
            get_tickets('users', []),
            get_tickets('types', []),
            get_tickets('customers', []),
          ]);

        const [usersData, typesData, customersData] = await Promise.all([
          usersResponse.json(),
          typesResponse.json(),
          customersResponse.json(),
        ]);

        switch (false) {
          case customersResponse.ok:
            throw customersData;
          case usersResponse.ok:
            throw usersData;
          case typesResponse.ok:
            throw typesData;
        }

        setUsers(usersData.users);
        setTypes(typesData.types);
        setCustomers(customersData.customers);
      } catch (e: any) {
        console.log(e);
        setMError(e?.error?.error ? e?.error?.error : e?.error);
      }
    };

    fetchData();
  }, [reload]);

  return (
    <StoreContext.Provider
      value={{
        reload,
        setReload,
        gettingEntries,
        setGettingEntries,
        status,
        mError,
        setMError,
        enteries,
        setEnteries,
        NewTicket,
        setNewTicket,
        periorities,
        selCustomer,
        setSelCustomer,
        selState,
        setSelState,
        editTicket,
        setEditTicket,
        tickets,
        setTickets,
        users,
        setUsers,
        types,
        setTypes,
        customers,
        setCustomers,
        telephones,
        setTelephones,
        showClients,
        setShowClients,
        theme,
        setTheme,
        selectedCustomer,
        setSelectedCustomer,
      }}
    >
      {children}
      {showClients ? (
        <>
          <div
            aria-label="Enteries BackDrop"
            className="backdrop_col z-5 absolute inset-0 bottom-0 left-0 right-0 top-0"
            onClick={() => {
              setShowClients(false);
            }}
          >
            {!enteries.length ? <Loading /> : ''}
          </div>
          {customers.length ? (
            <div className="center z-6 w-[100vw] md:w-[80vw]">
              <CustomersTable RoleId={RoleId} onReload={setReload} />
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}

      {selectedCustomer != -1 && (
        <>
          <div
            aria-label="Enteries BackDrop"
            className="backdrop_col z-9 absolute inset-0 bottom-0 left-0 right-0 top-0"
            onClick={() => {
              setEnteries([]);
              setSelectedCustomer(-1);
            }}
          >
            {gettingEntries ? <Loading /> : ''}
          </div>
          <div className="center z-10">
            <EnteriesTable
              RoleId={RoleId}
              customer={String(selectedCustomer)}
            />
          </div>
        </>
      )}
      {mError?.length && !pathname?.toLowerCase().includes('login') ? (
        <div className="fixed bottom-4 left-16 z-[200] animate-bounce">
          <Snippet
            hideCopyButton
            hideSymbol
            classNames={{
              pre: 'text-center font-bold w-full',
            }}
            color={mError.startsWith('s-') ? 'success' : 'danger'}
            size="lg"
            style={{ width: '90vw' }}
            variant="shadow"
          >
            {mError.startsWith('s-') ? mError.slice(2) : mError}
          </Snippet>
        </div>
      ) : (
        ''
      )}
    </StoreContext.Provider>
  );
}
