export const get_dns_record = async (
  CustomerId?: number,
  RecordId?: number,
  RecordName?: string,
) => {
  return await fetch('/api/customer/get_dns_record', {
    method: 'POST',
    body: JSON.stringify({
      CustomerId,
      RecordId,
      RecordName,
    }),
  });
};
export const delete_dns_record = async (Record: number) => {
  return await fetch('/api/customer/delete_dns_record', {
    method: 'POST',
    body: JSON.stringify({
      Record,
    }),
  });
};
export const update_dns_record = async (Record: Partial<dns_record>) => {
  return await fetch('/api/customer/update_dns_record', {
    method: 'POST',
    body: JSON.stringify({
      Record,
    }),
  });
};

export const insert_dns_record = async (Record: Partial<dns_record>) => {
  return await fetch('/api/customer/insert_dns_record', {
    method: 'POST',
    body: JSON.stringify({
      Record,
    }),
  });
};

export const get_cloudns_record = async (recordName: string) => {
  return await fetch('/api/customer/get_cloudns_record', {
    method: 'POST',
    body: JSON.stringify({
      recordName,
    }),
  });
};
export const delete_cloudns_record = async (Record: number) => {
  return await fetch('/api/customer/delete_cloudns_record', {
    method: 'POST',
    body: JSON.stringify({
      Record,
    }),
  });
};
export const update_cloudns_record = async (
  Record: Partial<cloudns_record>,
) => {
  return await fetch('/api/customer/update_cloudns_record', {
    method: 'POST',
    body: JSON.stringify({
      Record,
    }),
  });
};

export const insert_cloudns_record = async (Record: any) => {
  return await fetch('/api/customer/add_cloudns_record', {
    method: 'POST',
    body: JSON.stringify({
      Record,
    }),
  });
};
