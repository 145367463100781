export const arabicTranslation = {
  's-User deleted successfully': 'تم حذف المستخدم بنجاح',
  's-Password reset successfully': 'تم إعادة تعيين كلمة المرور بنجاح',
  Delete: 'حذف',
  Id: 'معرف',
  Login: 'تسجيل الدخول',
  'Reset password': 'إعادة تعيين كلمة المرور',
  New: 'جديد',
  'User Id': 'معرف المستخدم',
  'User Login': 'تسجيل دخول المستخدم',
  delete: 'حذف',
  reset: 'إعادة تعيين',
  attachments: 'المرفقات',
  add_attachment: 'إضافة مرفق:',
  type: 'النوع',
  name: 'الاسم',
  date: 'التاريخ',
  user: 'المستخدم',
  view_delete: 'عرض / حذف',
  edit_delete: 'تعديل / حذف',
  empty: 'فارغ',
  close: 'إغلاق',
  ticketHistory: 'تاريخ التذكرة',
  action: 'الإجراء',
  column: 'العمود',
  oldValues: 'القيم القديمة',
  newValues: 'القيم الجديدة',
  resolveDate: 'تاريخ الحل',
  stateTime: 'وقت الحالة',
  tableLabel: 'مثال على جدول بخلايا مخصصة',
  allItemsSelected: 'جميع العناصر المحددة ',
  cancel: 'إلغاء',
  edit: 'تعديل',
  open: 'فتح',
  errorDeleting: 'حدث خطأ أثناء حذف العميل!',
  show: 'عرض',
  email: 'البريد الإلكتروني',
  dns: 'إسم النطاق',
  publicId: 'رقم التعريف',
  sms: 'الرسائل النصية',
  // dashboard
  waiting: 'في الإنتظار',
  resolved: 'تم حله',
  rejected: 'مرفوض',
  pending: 'في الانتظار',
  closed: 'مغلق',
  Open: 'مفتوح',
  total: 'المجموع',
  tickets: 'التذاكر',
  duration: 'المدة',
};
