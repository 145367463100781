import { Button } from '@nextui-org/button';
import {
  ModalContent,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Select,
  SelectItem,
  Checkbox,
  Chip,
  Tooltip,
  Snippet,
} from '@nextui-org/react';
import { useContext, useEffect, useState } from 'react';
import { FaRegSave } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { t } from 'i18next';

import { get_tickets } from '@/client_scripts/fetch_tikcets_api';
import {
  delete_cloudns_record,
  delete_dns_record,
  get_cloudns_record,
  get_dns_record,
  insert_cloudns_record,
  insert_dns_record,
  update_cloudns_record,
} from '@/client_scripts/fetch_record_api';
import { show_error } from '@/client_scripts/functions';
import StoreContext from '@/scripts/storeContext';

import Loading from '../Loading/Loading';

const recordTypes = ['A', 'CNAME'];

export default function DnsForm(props: {
  customerId: number;
  reload: any;
  setEdit: any;
}) {
  const { onOpen, isOpen, onOpenChange, onClose } = useDisclosure();
  const {
    onOpen: onConfirmOpen,
    isOpen: isConfirmOpen,
    onOpenChange: onConfirmOpenChange,
  } = useDisclosure();
  const [name, setName] = useState('');
  const [dnsName, setDnsName] = useState('');
  const [dnsState, setDnsState] = useState(t('Checking') + '...');
  const [recordtype, setRecordtype] = useState(0);
  const [insertting, setInsertting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isFrpc, setIsFrpc] = useState(true);
  const [isDdns, setIsDdns] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [ipOrDomain, setIpOrDomain] = useState('0.0.0.0');
  const [ddnsValue, setDdnsValue] = useState(t('Getting') + '...');
  const [ClientRecords, setClientRecords] = useState<dns_record[]>([]);
  const [SelectedRecord, setSelectedRecord] = useState<number>(0);
  const [SelectedCloudnsRecord, setSelectedCloudnsRecord] = useState<any>(null);
  const { setMError } = useContext(StoreContext);

  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9.-]+(?:\.[a-zA-Z]{2,}))$/;
  const ipRegex = /^[0-9.]*$/;

  const handleInputChange = (e: any) => {
    const value = e.target.value;

    // Allow only digits and dots, and ensure the format is correct
    if (recordtype === 0) {
      if (ipRegex.test(value)) {
        const segments = value.split('.');

        if (
          segments.length <= 4 &&
          segments.every(
            (seg: string) =>
              seg === '' || (Number(seg) >= 0 && Number(seg) <= 255),
          )
        ) {
          setIpOrDomain(value);
        }
      }
    } else if (recordtype === 1) {
      const value = e.target.value;

      // Allow only valid domain characters: letters, numbers, hyphens, and dots
      if (/^[a-zA-Z0-9.-]*$/.test(value)) {
        setIpOrDomain(value);
      }
    }
  };

  useEffect(() => {
    if (SelectedRecord === 0) {
      setDnsName('');
      setIpOrDomain('');
      setDdnsValue(t('Select record'));
      setIsFrpc(false);
      setDnsState(t('new'));
    } else {
      const record = ClientRecords.find(
        (item) => item.DnsRecordId == SelectedRecord,
      );

      if (!record) return;
      get_cloudns_record_(record.DnsRecordName);
    }
  }, [SelectedRecord]);

  const get_cloudns_record_ = async (recordName: string) => {
    setLoadData(true);
    setSelectedCloudnsRecord(null);
    const cloudnsrecord = await get_cloudns_record(recordName);
    const mcloudnsrecord = await cloudnsrecord.json();

    if (!cloudnsrecord.ok) {
      show_error(setMError, mcloudnsrecord);
      setIsFrpc(false);
      setIsDdns(true);
      setDnsName(recordName);
      setLoadData(false);
      setDnsState(t('Not Found'));
      setIsDdns(false);

      return;
    }
    const myrecord = mcloudnsrecord.record;

    setSelectedCloudnsRecord(myrecord);
    setDnsName(myrecord.host);
    setIsFrpc(myrecord.isFrpc);
    setIsDdns(myrecord.ddnsUrl != undefined);
    if (!myrecord.isFrpc) {
      setRecordtype(myrecord.type === 'A' ? 0 : 1);
      setIpOrDomain(myrecord.record);
      if (myrecord.ddnsUrl) {
        setDdnsValue(myrecord.ddnsUrl);
        setIsDdns(true);
      } else {
        setDdnsValue(t('Disabled'));
      }
    }
    setDnsState(t('OK'));
    setLoadData(false);
    // console.log(mcloudnsrecord)
  };

  useEffect(() => {
    if (props.customerId === -1) return;
    onOpen();
    get_client_();
  }, [props.customerId]);

  const get_client_ = async () => {
    const check = await get_dns_record(props.customerId);
    const mcheck = await check.json();

    if (!check.ok) {
      setMError(t('Something went wrong') + '!!');
      console.log(mcheck);

      return;
    }

    if (mcheck.rows.length) {
      console.log(mcheck);
      setClientRecords(mcheck.rows);
      setSelectedRecord(mcheck.rows[0].DnsRecordId);
    }

    const entry = await get_tickets('customers', [
      { type: 'customerId', value: props.customerId },
    ]);
    const mentry = await entry.json();

    if (entry.ok) {
      const mmentry = mentry.customers[0];

      setName(mmentry.CustomerName);
      console.log(mmentry);
    } else {
      setMError('Something went whrong');
    }
  };

  const update_entry_ = async () => {
    if (!name.length || !dnsName.length) {
      setMError('Fill all required fields!!');

      return;
    }
    try {
      setInsertting(true);
      if (SelectedRecord === 0) {
        const add_dns = await insert_dns_record({
          CustomerId: props.customerId,
          DnsRecordName: dnsName,
        });
        const check_add_dns = await add_dns.json();

        console.log(check_add_dns);
        if (!add_dns.ok) {
          throw check_add_dns;
        }
        const checkcloudns = await get_cloudns_record(dnsName);

        setLoadData(true);
        if (checkcloudns.ok) {
          await get_client_();
          setMError('s-The domain is existing on Cloudns');
        } else {
          await insert_cloudns_record_();
        }
      } else if (SelectedCloudnsRecord === null) {
        await insert_cloudns_record_();
      } else if (SelectedCloudnsRecord) {
        if (SelectedCloudnsRecord['record-type'] != recordTypes[recordtype]) {
          await delete_cloudns_record(SelectedCloudnsRecord.id);
          await insert_cloudns_record_();
        } else {
          const record = {
            recordId: SelectedCloudnsRecord['id'],
            recordName: dnsName,
            ddns:
              isDdns != (SelectedCloudnsRecord.dynamicurl_status == 1) &&
              !isFrpc &&
              recordtype !== 1
                ? isDdns
                  ? '1'
                  : '0'
                : '2',
            recordValue: isFrpc ? 'frpc.bmvie.net' : ipOrDomain,
            type: recordtype === 1 || isFrpc ? 'CNAME' : 'A',
          };

          await update_cloudns_record(record);
        }
      }
    } catch (e: any) {
      show_error(setMError, e);
      setLoadData(false);
    }
    await get_client_();
    setLoadData(false);
    props.reload((prev: boolean) => !prev);
    setInsertting(false);
  };

  const handleDeleteRocord = async () => {
    if (SelectedRecord === 0) {
      setMError('Select a record !!!');

      return;
    }
    setDeleting(true);
    try {
      if (SelectedCloudnsRecord) {
        const delete_cloudns = await delete_cloudns_record(
          SelectedCloudnsRecord.id,
        );
        const mdelete_cloudns = await delete_cloudns.json();

        if (!delete_cloudns.ok) {
          throw mdelete_cloudns;
        }
      }

      const delete_dns = await delete_dns_record(SelectedRecord);
      const mdelete_dns = await delete_dns.json();

      if (!delete_dns.ok) {
        throw mdelete_dns;
      }
      setMError('s-Record deleted successfully');
      setDeleting(false);
      await get_client_();
    } catch (e: any) {
      setDeleting(false);
      show_error(setMError, e);
    }
  };

  const insert_cloudns_record_ = async () => {
    if (!isFrpc && recordtype === 2 && !domainRegex.test(ipOrDomain)) {
      throw { error: 'Enter a valid Domain Name' };
    }
    setInsertting(true);
    const record = {
      host: dnsName
        ? dnsName
        : ClientRecords.find((item) => item.DnsRecordId === SelectedRecord),
      record: isFrpc ? 'frpc.bmvie.net' : ipOrDomain ? ipOrDomain : '0.0.0.0',
      ddns: isDdns && !isFrpc && recordtype !== 1 ? '1' : '0',
      type: isFrpc ? 'CNAME' : recordtype === 0 ? 'A' : 'CNAME',
    };
    const insert_cloudns = await insert_cloudns_record(record);

    if (insert_cloudns.ok) {
      setMError('s-Record Added Successfully');
      setInsertting(false);
      await get_client_();
    } else {
      setInsertting(false);
      throw {
        error: 'Error when inserting on Cloudns',
        info: await insert_cloudns.json(),
      };
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          props.setEdit(null);
        }}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                Dns License Customer : {props.customerId} {name}
              </ModalHeader>
              <ModalBody>
                {name?.length && !loadData ? (
                  <>
                    <Select
                      defaultSelectedKeys={[String(SelectedRecord)]}
                      label="Select Dns record"
                      selectionMode="single"
                      value={SelectedRecord}
                      onChange={(e) =>
                        setSelectedRecord(parseInt(e.target.value))
                      }
                    >
                      {[
                        { DnsRecordId: 0, DnsRecordName: 'Add new' },
                        ...ClientRecords,
                      ].map((item) => (
                        <SelectItem
                          key={item.DnsRecordId}
                          value={item.DnsRecordId}
                        >
                          {item.DnsRecordName}
                        </SelectItem>
                      ))}
                    </Select>

                    {!isFrpc ? (
                      <Select
                        defaultSelectedKeys={[String(recordtype)]}
                        label="Record Type"
                        selectionMode="single"
                        onChange={(e) =>
                          setRecordtype(parseInt(e.target.value))
                        }
                      >
                        <SelectItem key={0}>A</SelectItem>
                        <SelectItem key={1}>CNAME</SelectItem>
                      </Select>
                    ) : (
                      ''
                    )}
                    <Input
                      isRequired
                      endContent={
                        <div className="pointer-events-none flex items-center">
                          <span className="text-default-400">.bmvie.net</span>
                        </div>
                      }
                      label="Customer Dns Name"
                      type="text"
                      value={dnsName}
                      onChange={(e) => setDnsName(e.target.value)}
                    />
                    {!isFrpc ? (
                      <Input
                        isClearable
                        label={recordtype === 0 ? 'Public IP' : 'Domain'}
                        type="text"
                        value={ipOrDomain}
                        onChange={handleInputChange}
                        onClear={() => setIpOrDomain('')}
                      />
                    ) : (
                      ''
                    )}
                    {isDdns ? (
                      // <Input classNames={{ input: 'text-default-400' }} label="DDNS URL" value={ddnsValue} endContent={<Tooltip content="Copy DDNS URL"><Button onPress={() => {navigator.clipboard.writeText(ddnsValue); setMError('s-Text copied to the clipboard')}} radius='full' variant='light' size='sm' isIconOnly><FaRegCopy /></Button></Tooltip>} />
                      <Snippet
                        hideSymbol
                        codeString={ddnsValue}
                        color="default"
                      >
                        {ddnsValue.slice(0, 40) + '...'}
                      </Snippet>
                    ) : (
                      ''
                    )}
                    <div className="grid grid-cols-3 grid-rows-1">
                      <Checkbox
                        isDisabled={recordtype === 1 || isFrpc}
                        isSelected={isDdns && !isFrpc}
                        onChange={(e) => setIsDdns(e.target.checked)}
                      >
                        {' '}
                        DDNS{' '}
                      </Checkbox>
                      <Checkbox
                        isSelected={isFrpc}
                        onChange={(e) => setIsFrpc(e.target.checked)}
                      >
                        {' '}
                        frpc{' '}
                      </Checkbox>
                      <div>
                        Dns State: <Chip>{dnsState}</Chip>
                      </div>
                    </div>
                  </>
                ) : (
                  <Loading />
                )}
              </ModalBody>
              <ModalFooter>
                <Tooltip content="Save record">
                  <Button
                    isIconOnly
                    isLoading={insertting}
                    onClick={update_entry_}
                  >
                    <FaRegSave />
                  </Button>
                </Tooltip>
                {SelectedRecord ? (
                  <Tooltip content="Delete record">
                    <Button
                      isIconOnly
                      isLoading={deleting}
                      onClick={onConfirmOpen}
                    >
                      <MdDeleteForever />
                    </Button>
                  </Tooltip>
                ) : (
                  ''
                )}
                <Tooltip content="Close">
                  <Button isIconOnly onPress={onClose}>
                    <IoMdCloseCircleOutline />
                  </Button>
                </Tooltip>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal isOpen={isConfirmOpen} onOpenChange={onConfirmOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Confirmation</ModalHeader>
              <ModalBody>
                {' '}
                Are you sure?! you are going to delete the record {
                  dnsName
                } !!{' '}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  isLoading={deleting}
                  onPress={async () => {
                    await handleDeleteRocord();
                    onClose();
                  }}
                >
                  Confirm
                </Button>
                <Button onPress={onClose}>Cancel</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
