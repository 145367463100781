export const frenchTranslation = {
  's-User deleted successfully': 'Utilisateur supprimé avec succès',
  's-Password reset successfully': 'Mot de passe réinitialisé avec succès',
  Delete: 'Supprimer',
  Id: 'Id',
  Login: 'Connexion',
  'Reset password': 'Réinitialiser le mot de passe',
  New: 'Nouveau',
  'User Id': 'ID utilisateur',
  'User Login': 'Identifiant utilisateur',
  delete: 'supprimer',
  reset: 'réinitialiser',
  attachments: 'Pièces jointes',
  add_attachment: 'Ajouter une pièce jointe:',
  type: 'Type',
  name: 'Nom',
  date: 'Date',
  user: 'Utilisateur',
  view_delete: 'Voir / Supprimer',
  edit_delete: 'Modifier / Supprimer',
  empty: 'vide',
  close: 'Fermer',
  ticketHistory: 'Historique du ticket',
  action: 'Action',
  column: 'Colonne',
  oldValues: 'Anciennes valeurs',
  newValues: 'Nouvelles valeurs',
  resolveDate: 'Date de résolution',
  stateTime: "Temps d'état",
  tableLabel: 'Exemple de tableau avec des cellules personnalisées',
  allItemsSelected: 'Tous les éléments sélectionnés ',
  cancel: 'Annuler',
  edit: 'Éditer',
  open: 'Ouvrir',
  errorDeleting: "Une erreur s'est produite lors de la suppression du client !",
  show: 'Afficher',
  email: 'Email',
  dns: 'DNS',
  publicId: 'Id Publique',
  sms: 'SMS',
  // dashboard
  waiting: 'En attente',
  resolved: 'Résolu',
  rejected: 'Rejeté',
  pending: 'En attente',
  closed: 'Fermée',
  Open: 'Ouvert',
  total: 'Total',
  tickets: 'Tickets',
  duration: 'Durée',
};
