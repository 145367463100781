import { Button } from '@nextui-org/button';
import {
  ModalContent,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
} from '@nextui-org/react';
import { useContext, useState } from 'react';
import { IoPersonAddSharp } from 'react-icons/io5';

import { Months } from '@/client_scripts/constants';
import {
  get_tickets,
  insert_customer,
  insert_phones,
} from '@/client_scripts/fetch_tikcets_api';
import CustomSelect from '@/elements/CustomSelect/CustomSelect';
import StoreContext from '@/scripts/storeContext';

export default function NewClientForm(props: {
  setClient?: any;
  setReload?: any;
}) {
  const { onOpen, isOpen, onOpenChange, onClose } = useDisclosure();
  const [phones, setPhones] = useState<selectLabel[]>([]);
  const [name, setName] = useState('');
  const [wilaya, setWilaya] = useState('');
  const [pid, setPid] = useState('');
  const [email, setEmail] = useState('');
  const [LicenseMonth, setLicenseMonth] = useState<{
    label: string;
    value: MonthNumber;
  }>({ label: 'January', value: 1 });
  const [insertting, setInsertting] = useState(false);
  const { setMError, customers } = useContext(StoreContext);

  const insert_customer_ = async () => {
    setInsertting(true);
    const id = await insert_customer({
      name,
      wilaya,
      pid,
      licenseMonth: LicenseMonth.value,
      email,
    });
    const mid = await id.json();

    if (id.ok) {
      if (phones.length) {
        const mphon = await insert_phones(
          mid.rows.id,
          phones.map((item) => String(item.label)),
        );
        const mmphon = await mphon.json();

        if (!mphon.ok) {
          setMError("Can't insert phones!!");
        } else {
          setMError('s-Client inserted successfully');
          if (props.setReload) props.setReload((prev: boolean) => !prev);
          if (props.setClient) {
            const sel = await get_tickets('customers', [
              { type: 'customerId', value: mid.rows.id },
            ]);
            const msel = await sel.json();

            if (sel.ok)
              props.setClient({
                label: msel.customers[0].CustomerName,
                value: msel.customers[0].CustomerId,
              });
          }
          onClose();
        }
      } else {
        setMError('s-Client inserted successfully');
        if (props.setReload) props.setReload((prev: boolean) => !prev);
        if (props.setClient) {
          const sel = await get_tickets('customers', [
            { type: 'customerId', value: mid.rows.id },
          ]);
          const msel = await sel.json();

          if (sel.ok)
            props.setClient({
              label: msel.customers[0].CustomerName,
              value: msel.customers[0].CustomerId,
            });
        }
        onClose();
      }
    } else {
      setMError(mid.error);
    }
    setInsertting(false);
  };

  return (
    <>
      <Button isIconOnly size="sm" variant="flat" onPress={onOpen}>
        <IoPersonAddSharp />
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Add new customer</ModalHeader>
              <ModalBody>
                <Input
                  label="Customer Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  label="Wilaya code"
                  max="58"
                  min="1"
                  type="number"
                  value={wilaya}
                  onChange={(e) => setWilaya(e.target.value)}
                />
                <Input
                  label="Public Id"
                  min={1}
                  type="number"
                  value={pid}
                  onChange={(e) => setPid(e.target.value)}
                />
                <Input
                  label="Email"
                  min={1}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <CustomSelect
                  Label="Licence Month"
                  options={Months.map((item, index) => ({
                    label: item,
                    value: index + 1,
                  }))}
                  placeholder="Customer licence month"
                  selectedOptions={LicenseMonth}
                  setSelectedOptions={setLicenseMonth}
                />
                <CustomSelect
                  createable
                  isMulti
                  options={[]}
                  placeholder="Customer Phones..."
                  selectedOptions={phones}
                  setSelectedOptions={setPhones}
                />
              </ModalBody>
              <ModalFooter>
                <Button isLoading={insertting} onClick={insert_customer_}>
                  Save
                </Button>
                <Button onPress={onClose}>Close</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
