import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { englishTranslation } from './english';
import { arabicTranslation } from './arabic';
import { frenchTranslation } from './french';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: englishTranslation,
    },
    ar: {
      translation: arabicTranslation,
    },
    fr: {
      translation: frenchTranslation,
    },
    // Add more languages as needed
  },
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
